export const getMode = connection => {
  return connection.rpcEndpoint.indexOf('devnet') > -1 ? 'devnet' : 'mainnet';
};

export const BOT_MIN_RATE = 10;
export const BOT_MAX_RATE = 200;
export const BOT_DEFAULT_RATE = 50;

export const BOT_MIN_COUNT = 20;
export const BOT_DEFAULT_COUNT = 1000;
