import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HelmetLayout from '../../components/HelmetLayout';
import Create from './container/Create';
import Manage from './container/Manage';
import Details from './container/Details';
import CreateBump from './container/CreateBump';
import CreateVolume from './container/CreateVolume';

const MoonShot = () => {
  return (
    <>
      <HelmetLayout
        title='Moonshot Bot: Generate Volume, Bump for your tokens on Moonshot'
        description="Moonshot bot makes multiple trades boosting your token's onchain volume and transactions"
        ogImage='https://app.hypersol.xyz/images/moonshot_bot.png'
      />
      <Routes>
        <Route path='start' element={<CreateBump />} />
        <Route path='bump-bot' element={<CreateBump />} />
        <Route path='volume-bot' element={<CreateVolume />} />
        <Route path='manage' element={<Manage />} />
        <Route path='manage/:campaignId' element={<Details />} />
      </Routes>
    </>
  );
};

export default MoonShot;
