import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

export const SUB_APP_NAVIGATION = [
  {
    name: 'Bumpi Bot',
    route: 'bump-bot',
    key: 'pumpfun-bump-bot',
    icon: <PlusCircleOutlined />,
  },
  {
    name: 'Volume Bot',
    route: 'volume-bot',
    key: 'pumpfun-volume-bot',
    icon: <PlusCircleOutlined />,
  },
  //   {
  //     name: 'Trending Bot',
  //     route: 'trending-bot',
  //     key: 'pumpfun-trending-bot',
  //     icon: <PlusCircleOutlined />,
  //   },
  {
    name: 'Manage Bots',
    route: 'manage',
    key: 'pumpfun-manage',
    icon: <PlusCircleOutlined />,
  },
];
