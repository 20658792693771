import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HelmetLayout from '../../components/HelmetLayout';
import Create from './container/Create';
import Manage from './container/Manage';
import Details from './container/Details';

const VolumeBot = () => {
  return (
    <>
      <HelmetLayout
        title='Volume Bot: Generate Volume for your tokens on Raydium'
        description="Volume bot makes multiple trades boosting your token's onchain volume"
        ogImage='https://app.hypersol.xyz/images/raydium_bolume_bot.png'
      />
      <Routes>
        <Route path='start' element={<Create />} />
        <Route path='manage' element={<Manage />} />
        <Route path='manage/:campaignId' element={<Details />} />
      </Routes>
    </>
  );
};

export default VolumeBot;
