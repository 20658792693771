import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HelmetLayout from '../../components/HelmetLayout';
import Manage from './container/Manage';
import Details from './container/Details';
import CreateBump from './container/CreateBump';
import CreateVolume from './container/CreateVolume';
import CreateTrending from './container/CreateTrending';

const Bumpi = () => {
  return (
    <>
      <HelmetLayout
        title='Bumpi Bot: Pumpfun bump bot'
        description='Bumpi bot performs microtrading on pumpfun tokens to increase the token transaction. And ensures higher visibility of token on pumpfun homepage'
        ogImage='https://app.hypersol.xyz/images/pumpfun_bump_bot.png'
      />
      <Routes>
        <Route path='start' element={<CreateBump />} />
        <Route path='bump-bot' element={<CreateBump />} />
        <Route path='volume-bot' element={<CreateVolume />} />
        {/* <Route path='trending-bot' element={<CreateTrending />} /> */}
        <Route path='manage' element={<Manage />} />
        <Route path='manage/:campaignId' element={<Details />} />
      </Routes>
    </>
  );
};

export default Bumpi;
