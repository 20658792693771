import React from 'react';
import volumebotstyle from '../style/volumebot.module.less';

export const Tutorial = () => {
  return (
    <section className={volumebotstyle.tutorial}>
      <div className={volumebotstyle.videoContent}>
        <iframe
          width='100%'
          height='200'
          src='https://www.youtube.com/embed/Z3dInV2yZbc'
          title='How to boost token volume on Solana using volume bot.'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen></iframe>
      </div>
      <div className={volumebotstyle.textContent}>
        <h2>Volume Bot</h2>
        <p>
          The Volume Bot is designed for generating volume for tokens live on
          Raydium.
        </p>
      </div>
    </section>
  );
};
