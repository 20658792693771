import axios from 'axios';
import { BARE_METAL_BACKEND_URL } from '../../../envs/urls';

export const getRadiyumPool = ({ mintAddress }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${BARE_METAL_BACKEND_URL}/api/v1/mtb/radiyum-pool`,
      params: {
        mintAddress,
      },
    })
      .then(result => {
        resolve(result?.data?.data?.[0]);
      })
      .catch(err => {
        console.log('error is', err);
        reject('Unable to find bonding curve status');
      });
  });
};

export const getSolPrice = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `https://api.diadata.org/v1/assetQuotation/Solana/0x0000000000000000000000000000000000000000`,
    })
      .then(result => {
        console.log('result===>', result?.data?.Price);
        resolve(result?.data?.Price);
      })
      .catch(err => {
        console.log('error is', err);
        reject('Unable to fetch balance');
      });
  });
};
