import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

export const SUB_APP_NAVIGATION = [
  {
    name: 'Bump bot',
    route: 'bump-bot',
    key: 'moonshot-bump-bot',
    icon: <PlusCircleOutlined />,
  },
  {
    name: 'Volume bot',
    route: 'volume-bot',
    key: 'moonshot-volume-bot',
    icon: <PlusCircleOutlined />,
  },
  {
    name: 'Manage',
    route: 'manage',
    key: 'moonshot-manage',
    icon: <PlusCircleOutlined />,
  },
];
