import React from 'react';
import bumpiStyle from '../style/bumpi.module.less';

export const BumpiInfo = () => (
  <div className={bumpiStyle.infoContainer}>
    <section className={bumpiStyle.section}>
      <h2>Here's how to start bumping your token with the Bumpi Bot::-</h2>
      <ul>
        <li>
          <b>Load the Token:</b> Begin by loading the token using its mint
          address.
        </li>
        <li>
          <b>Set Total Transactions:</b> Decide on the total number of
          transactions you want the bot to perform.
        </li>
        <li>
          <b>Adjust the Rate:</b> Set the rate, which is the number of
          transactions per mint the bot will execute on the token. Each
          transaction consists of one buy and one sell, and the high volume of
          small transactions helps maintain the token’s visibility at the top of
          the homepage.
        </li>
        <li>
          <b>Choose Transaction Amount:</b> Select the amount of SOL the bot
          will use for each buy and sell transaction.
        </li>
      </ul>
      <br />
      <p>
        For more information or if you have any questions, please contact us on
        Telegram or Twitter.
      </p>
    </section>
  </div>
);
export const VolumeInfo = () => (
  <div className={bumpiStyle.infoContainer}>
    <section className={bumpiStyle.section}>
      <h2>
        Here's how to generate volume for your token with the Volume Bot::-
      </h2>
      <ul>
        <li>
          <b>Load the Token:</b> Begin by loading the token using its mint
          address.
        </li>
        <li>
          <b>Set Total Transactions:</b> Adjust the total number of transactions
          based on amount of volume you want to genrate.
        </li>
        <li>
          <b>Adjust the Rate:</b> Set the rate, which is the number of
          transactions per mint the bot will execute on the token. Each
          transaction consists of one buy and one sell, and the high volume
          helps to maintain the token’s visibility at the top of the homepage.
        </li>
        <li>
          <b>Choose Transaction Amount:</b> Select the amount of SOL the bot
          will use for each buy and sell transaction.
        </li>
      </ul>
      <br />
      <p>
        For more information or if you have any questions, please contact us on
        Telegram or Twitter.
      </p>
    </section>
  </div>
);
