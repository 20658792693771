import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

export const SUB_APP_NAVIGATION = [
  {
    name: 'Create',
    route: 'start',
    key: 'volumebot-start',
    icon: <PlusCircleOutlined />,
  },
  {
    name: 'Manage',
    route: 'manage',
    key: 'bolumebot-manage',
    icon: <PlusCircleOutlined />,
  },
];
