import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetLayout = ({ title, description, keywords, ogImage }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      {ogImage && <meta property='og:image' content={ogImage} />}
      {ogImage && <meta property='twitter:image' content={ogImage} />}
    </Helmet>
  );
};

export default HelmetLayout;
